import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";
import RentwareArticlesGrid from "../../../components/rentwareArticlesGrid";
import useTranslation from "../../../hooks/useTranslation";

const DemoCartPage = () => {
	const { language } = useTranslation();
	const data = useStaticQuery(graphql`
		{
			allFile(filter: { base: { regex: "/tesla-dark.jpg/" } }) {
				nodes {
					base
					childImageSharp {
						gatsbyImageData(width: 1440)
					}
				}
			}
		}
	`);
	const getTeslaImage = data.allFile.nodes.find((element) => {
		if (element.base === `tesla-dark.jpg`) {
			return true;
		}

		return false;
	});
	const teslaImg = getImage(getTeslaImage);

	const articles = [
		{
			h1: "Tesla Model S",
			h2:
				language === "de"
					? "402mi Reichweite (EPA geschätzt)"
					: "402mi Range (EPA est.)",
			text:
				language === "de"
					? "Das Tesla Model S war 2013 'World Green Car of the Year', 2013 'Motor Trend Car of the Year', 'Automobile Magazine's 2013 Car of the Year', 'Time Magazine's Best 25 Inventions of the Year 2012' und 'Consumer Reports' top scoring car in road testing'."
					: "The Tesla Model S was the 2013 World Green Car of the Year, 2013 Motor Trend Car of the Year, Automobile magazine's 2013 Car of the Year, Time Magazine's Best 25 Inventions of the Year 2012, and Consumer Reports' top-scoring car in road testing.",
			href: "/widgets/demo/tesla",
			image: teslaImg,
			articleId: "GXHBU7",
			view: "",
		},
	];

	return (
		<LayoutWidget>
			<Seo
				seo={{
					metaTitle: "Demo",
					metaDescription: "It looks like you got to the demo page",
				}}
			/>
			<div className="mb-16 hidden text-center lg:block">
				<StaticImage
					src="../../../images/tesla.jpg"
					alt="Tesla red"
					layout="constrained"
					width={1440}
					quality="80"
				/>
			</div>

			<RentwareArticlesGrid articles={articles} />
		</LayoutWidget>
	);
};

export default DemoCartPage;
